//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import GetAppPopup from "./__GetAppPopup";
export default {
  name: 'GetAppPopupSoon',
  components: {
    'app-get-app-popup': GetAppPopup
  },
  methods: {
    closePopup: function closePopup() {
      this.$store.dispatch('modals/setGetAppModalSoon', {
        isOpen: false
      });
    }
  }
};